/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { Link, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import CampHoc from '../../components/CampHoc';

// eslint-disable-next-line react/prop-types
const KaamoshiihtoPallasYllastunturinKansallispuistossa = ({ data }) => {
  const seo = {
    url: 'https://www.discoverlapland.fi/hiihtovaellukset/kaamoshiihto-pallas-yllastunturin-kansallispuistossa',
    image: `https://www.discoverlapland.fi${data.imageSEO.childImageSharp.gatsbyImageData.images.fallback.src}`,
    title: 'Kaamoshiihto Pallas-Yllästunturin kansallispuistossa',
    description:
      'Kaipaatko hetkeä hiljaisuudessa kauniissa talvimaisemissa? Valmistaudu talven tuloon ja lähdekokemaan kaamos lumoavimmillaan. Kutsumme sinut hiihtämään kanssamme Vuontisjärveltä läpi lumisen tunturiylängön kohti tunnelmallista Nammalakurua, jossa nautimme kaamoksen pimeydestä ja hiljaisuudesta kaksi yötä.',
  };

  return (
    <CampHoc
      heroImage={data.campBg.childImageSharp.gatsbyImageData}
      seo={seo}
      camp="kaamoshiihto-pallas-yllastunturin-kansallispuistossa"
    >
      <div className="camp-text-content">
        <h2>Kaamoshiihto Pallas-Yllästunturin kansallispuistossa</h2>
        <h3>6.-8.12.2024</h3>
        <p className="bolded">
          Kaipaatko hetkeä hiljaisuudessa kauniissa talvimaisemissa? Valmistaudu
          talven tuloon ja lähdekokemaan kaamos lumoavimmillaan. Kutsumme sinut
          hiihtämään kanssamme Vuontisjärveltä läpi lumisen tunturiylängön kohti
          tunnelmallista Nammalakurua, jossa nautimme kaamoksen pimeydestä ja
          hiljaisuudesta kaksi yötä.
        </p>
        <br />
        <p>
          Kaamos esiintyy pohjoisilla leveysasteilla talvisaikaan, jolloin
          aurinko ei nouse horisontin yläpuolelle. Lapin kaamos on
          ainutlaatuinen kokemus, kun luonto hiljenee talviunilleen, ja kuulet
          vain hiljaista tuulen kohinaa ja lumen narinaa askelten alla. Kaamos
          tuo myös mukanaan ainutlaatuisen tunnelman ja kauneuden. Se on myös
          oiva tilaisuus hidastaa tahtia ja nauttia hiljaisuudesta sekä
          pimeydestä, ja antaa keholle ja mielelle ansaittua lepoa.
        </p>
      </div>
      <div className="offering-img-row">
        <GatsbyImage
          image={data.campImg3.childImageSharp.gatsbyImageData}
          className="offering-img-vert"
          alt="image"
        />
        <GatsbyImage
          image={data.campImg4.childImageSharp.gatsbyImageData}
          className="offering-img-vert"
          alt="image"
        />
      </div>
      <div className="camp-text-content">
        <p className="bolded">Talviretken kulku kaamoksen keskellä</p>
        <p>
          Tapaamme Vuontisjärven parkkipaikalla 6.12.2024 klo 09.00. Tästä
          lähdemme hiihtäen /lumikengin kohti tunturiylänköä ja matkaa
          Nammalakuruun tulee noin 7 km. Liikumme hiljalleen lumisten maisemien
          halki, pysähdymme nauttimaan eväitä ja juomisia. Ympäröivä hiljaisuus
          kuiskailee korviisi ja saat syventyä omiin aatoksiin, tuntien mielesi
          rauhoittuvan. Kun viimein saavumme perille ennen pimeän tuloa, olemme
          iloisia tästä lumoavasta matkasta.
        </p>
        <br />
        <p>
          Vietämme idyllisessä Nammalakurun varaustuvassa kaksi yötä, jossa
          lepäämme ja nautimme kaamoksen hiljaisuudesta. Lauantaina 7.12.2024
          teemme päiväretken Rihmakuruun niiden kanssa, jotka haluavat, ja jos
          sää sallii. Rihmakuruun on matkaa noin 3 km suuntaansa. Kuru on upea
          nähtävyys, joka tarjoaa upeita maisemia ja pysähtynyttä tunnelmaa.
          Rihmakurun kodalla tulistelemme ja syömme maukkaita eväitä.
        </p>
        <br />
        <p>
          Sunnuntaina 8.12.2024 aloitamme kotimatkan takaisin kohti
          Vuontisjärveä ja perillä parkkipaikalle olemme noin klo 15.00.
        </p>
        <br />
        <p className="bolded">Mitä sinun tarvitsee huolehtia retkeä varten?</p>
        <p>
          Sinulle tulee huolehdittavaksi omat kuljetukset Vuontisjärvelle
          (suosimme ja suosittelemme kimppakyytejä,) omat ruokailusi sekä
          liikkumisvälineesi, jotka voivat olla tunturisukset (karvapohjilla),
          liukulumikengät (karvapohjilla), lumikengät, splitboard tai
          rando-sukset. Näiden avulla pääset liikkumaan kansallispuiston upeissa
          maisemissa ja nauttimaan talvisesta luonnosta. Jotta saat myös
          tavarasi ja tarvikkeesi tuotua mukanasi, tarvitset joko ison rinkan,
          ahkion tai pulkan millä vedät tarvikkeesi. Varaustuvassa voit käyttää
          omaa makuupussia tai makuupussin sisälakanaa, tuvassa on patja sekä
          petivaatteet.
        </p>
        <br />
        <p className="bolded">Vaatimustaso:</p>
        <p>
          Sinulla ei tarvitse olla aikaisempaa kokemusta talviretkeilystä.
          Mikäli lähdet liikkeelle suksilla, tulee sinun osata hiihtää. Matkalla
          on ylä- ja alamäkiä. Fyysisen kunnon puolesta retki vaatii
          peruskunnon.
        </p>
      </div>
      <div className="offering-img-row">
        <GatsbyImage
          image={data.campImg5.childImageSharp.gatsbyImageData}
          className="offering-img-vert"
          alt="image"
        />
        <GatsbyImage
          image={data.campImg6.childImageSharp.gatsbyImageData}
          className="offering-img-vert"
          alt="image"
        />
      </div>
      <div className="camp-text-content">
        <p className="bolded">Retken hinta on 350 € (sis alv.)</p>
        <p>
          Hintaan kuuluu: <br />
          Kaksi yötä Nammalakurun varaustuvassa <br />
          Oppaan palvelut retkellä (oppaana Sanni Lampinen) <br />
          Pakkauslista <br />
          Apua tarvikkeiden lainaamiseen / vuokraamiseen.
        </p>
        <br />
        <p className="bolded">
          Ilmoittaudu mukaan sähköpostitse{' '}
          <a className="bolded" href="mailto: anettelatvapiikkila@gmail.com">
            anettelatvapiikkila@gmail.com
          </a>{' '}
          ja toimi näin:
        </p>
        <p>
          OTSIKKO: Kaamoshiihto 6.-8.12.2024 <br />
          Vastaa meilissä ao. kysymyksiin: <br />
          Koko nimi: <br />
          Ikä: <br />
          Puhelinnumero: <br />
          Paikkakunta: <br />
          Oletko ollut aikaisemmin retkillämme? Jos olet, millä? <br />
          Mikä on terveydentilasi (onko polvi/nivelongelmia, astmaa jne.)?
        </p>
        <br />
        <p className="bolded">Retken varaus- ja peruutusehdot:</p>
        <p>
          Kun olet ilmoittautunut mukaan sähköpostitse, saat paluupostina
          materiaalikululaskun 150€. Kun lasku on maksettu, saat paluupostina
          retken materiaalit; pakkauslistan ja retkisuunnitelman.
          <br />
          <br />
          Hiihtovaelluksen loppulaskun 200€ saat sähköpostiisi retken
          aloituspäivänä, jolloin lasku tulee maksaa ja lähettää tästä
          kuittikopio osoitteeseen{' '}
          <a className="bolded" href="mailto: anettelatvapiikkila@gmail.com">
            anettelatvapiikkila@gmail.com
          </a>{' '}
          .
          <br />
          <br />
          Jos perut osallistumisesi retkelle missä kohtaa tahansa, pidätämme
          toimitetut retken materiaalien kulut sekä toimistokulut 150€.
          Ilmoittautumisen peruutus tulee tehdä sähköpostitse osoitteeseen{' '}
          <a className="bolded" href="mailto: anettelatvapiikkila@gmail.com">
            anettelatvapiikkila@gmail.com
          </a>{' '}
          .
          <br />
          <br />
          Lue lisää varaus - ja peruutusehdoistamme{' '}
          <Link className="bolded" to="/varaus-ja-peruutusehdot">
            tästä
          </Link>
          .
          <br />
          <br />
          Pidäthän huolen, että sinulla on voimassa oleva vapaa-ajan- tai
          matkavakuutus hiihtovaelluksen aikana.
          <br />
          <br />
          Vaellukselle otetaan maksimissaan 8 naista, minimi henkilömäärä on 5,
          jotta retki toteutuu.
          <br />
          <br />
          Mikäli sinulla on kysyttävää vaelluksesta, voit olla yhteydessä
          Anetteen joko meilitse{' '}
          <a className="bolded" href="mailto: anettelatvapiikkila@gmail.com">
            anettelatvapiikkila@gmail.com
          </a>{' '}
          tai soittamalla 040 772 4124.
        </p>
      </div>
    </CampHoc>
  );
};

export const query = graphql`
  {
    campBg: file(
      relativePath: { eq: "images/crosscountry/kaamoshiihto-7.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    campImg1: file(
      relativePath: { eq: "images/crosscountry/kaamoshiihto-4.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    campImg2: file(
      relativePath: { eq: "images/crosscountry/kaamoshiihto-6.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    campImg3: file(
      relativePath: { eq: "images/crosscountry/kaamoshiihto-1.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    campImg4: file(
      relativePath: { eq: "images/crosscountry/kaamoshiihto-2.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    campImg5: file(
      relativePath: { eq: "images/crosscountry/kaamoshiihto-5.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    campImg6: file(
      relativePath: { eq: "images/crosscountry/kaamoshiihto-8.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    imageSEO: file(
      relativePath: { eq: "images/crosscountry/kaamoshiihto-7.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1366, layout: FIXED)
      }
    }
  }
`;

export default KaamoshiihtoPallasYllastunturinKansallispuistossa;
